export default function(scheduler){

	// scheduler.config.year_x = 4;
	// scheduler.config.year_y = 3;
	// scheduler.xy.year_top = 0;

	scheduler.templates.year_date = function(date) {
		return scheduler.date.date_to_str(scheduler.locale.labels.year_tab + " %Y")(date);
	};
	scheduler.templates.year_month = scheduler.date.date_to_str("%F");
	scheduler.templates.year_scale_date = scheduler.date.date_to_str("%D");
	scheduler.templates.year_tooltip = function(s, e, ev) {
		return ev.text;
	};

	const isYearMode = function() {
		return scheduler._mode == "year";
	};
	
	const getCellDate = function(node){
		var day = scheduler.$domHelpers.closest(node, "[data-cell-date]");
		if(!day || !day.hasAttribute("data-cell-date")) return null;
		return scheduler.templates.parse_date(day.getAttribute("data-cell-date"));
	};

	// dbl click hook for scheduler._on_dbl_click
	scheduler.dblclick_dhx_year_grid= function(e) {
		if (isYearMode()) {
			const target = e.target;
			if(scheduler.$domHelpers.closest(target, ".dhx_before") || scheduler.$domHelpers.closest(target, ".dhx_after")){
				return false;
			}
			
			const date = getCellDate(target);
			
			if(date){
				const start = date;
				const end = this.date.add(start, 1, "day");
				if (!this.config.readonly && this.config.dblclick_create){
					this.addEventNow(start.valueOf(), end.valueOf(), e);
				}
			}
		}
	};
	
	scheduler.attachEvent("onEventIdChange", function(){
		if (isYearMode())
			this.year_view(true);
	});


	var old = scheduler.render_data;
	scheduler.render_data = function(evs) {
		if (!isYearMode()) return old.apply(this, arguments);
		for (var i = 0; i < evs.length; i++)
			this._year_render_event(evs[i]);
	};

	var clear = scheduler.clear_view;
	scheduler.clear_view = function() {
		if (!isYearMode()) return clear.apply(this, arguments);
		var dates = scheduler._year_marked_cells,
			div = null;
		for (var date in dates) {
			if (dates.hasOwnProperty(date)) {
				div = dates[date];
				div.classList.remove("dhx_year_event", "dhx_cal_datepicker_event");
				// div.className = "dhx_month_head";
				// div.removeAttribute("date");
				// div.removeAttribute("data-year-date");
			}
		}
		scheduler._year_marked_cells = {};
	};

	scheduler._hideToolTip = function() {
		if (this._tooltip) {
			this._tooltip.style.display = "none";
			this._tooltip.date = new Date(9999, 1, 1);
		}
	};

	scheduler._showToolTip = function(date, pos, e, src) {
		if (this._tooltip) {
			if (this._tooltip.date.valueOf() == date.valueOf()) return;
			this._tooltip.innerHTML = "";
		} else {
			var t = this._tooltip = document.createElement("div");
			t.className = "dhx_year_tooltip";
			if (this.config.rtl) t.className += " dhx_tooltip_rtl";
			document.body.appendChild(t);
			t.addEventListener("click", scheduler._click.dhx_cal_data);
			t.addEventListener("click", function(e){
				if(e.target.closest(`[${scheduler.config.event_attribute}]`)){
					const id = e.target.closest(`[${scheduler.config.event_attribute}]`).getAttribute(scheduler.config.event_attribute);
					scheduler.showLightbox(id);
				}
			});
		}
		var evs = this.getEvents(date, this.date.add(date, 1, "day"));
		var html = "";

		for (var i = 0; i < evs.length; i++) {
			var ev = evs[i];
			if(!this.filter_event(ev.id, ev))
				continue;

			var bg_color = (ev.color ? ("--dhx-scheduler-event-background:" + ev.color + ";") : "");
			var color = (ev.textColor ? ("--dhx-scheduler-event-color:" + ev.textColor + ";") : "");

			html += "<div class='dhx_tooltip_line' style='" + bg_color + "" + color + "' event_id='" + evs[i].id + "' "+this.config.event_attribute+"='"+evs[i].id+"'>";
			html += "<div class='dhx_tooltip_date' style='" + bg_color + "" + color + "'>" + (evs[i]._timed ? this.templates.event_date(evs[i].start_date) : "") + "</div>";
			html += "<div class='dhx_event_icon icon_details'>&nbsp;</div>";
			html += this.templates.year_tooltip(evs[i].start_date, evs[i].end_date, evs[i]) + "</div>";
		}

		this._tooltip.style.display = "";
		this._tooltip.style.top = "0px";


		if (document.body.offsetWidth - pos.left - this._tooltip.offsetWidth < 0)
			this._tooltip.style.left = pos.left - this._tooltip.offsetWidth + "px";
		else
			this._tooltip.style.left = pos.left + src.offsetWidth + "px";

		this._tooltip.date = date;
		this._tooltip.innerHTML = html;

		if (document.body.offsetHeight - pos.top - this._tooltip.offsetHeight < 0)
			this._tooltip.style.top = pos.top - this._tooltip.offsetHeight + src.offsetHeight + "px";
		else
			this._tooltip.style.top = pos.top + "px";
	};

	scheduler._year_view_tooltip_handler = function(e){
		if (!isYearMode()) return;

		var src = e.target || e.srcElement;
		if (src.tagName.toLowerCase() == 'a') // fix for active links extension (it adds links to the date in the cell)
			src = src.parentNode;
		if (scheduler._getClassName(src).indexOf("dhx_year_event") != -1)
			scheduler._showToolTip(scheduler.templates.parse_date(src.getAttribute("data-year-date")), scheduler.$domHelpers.getOffset(src), e, src);
		else
			scheduler._hideToolTip();
	};
	scheduler._init_year_tooltip = function() {
		scheduler._detachDomEvent(scheduler._els["dhx_cal_data"][0], "mouseover", scheduler._year_view_tooltip_handler);
		scheduler.event(scheduler._els["dhx_cal_data"][0], "mouseover", scheduler._year_view_tooltip_handler);
	};

	scheduler._get_year_cell = function(d) {
		var dateString = scheduler.templates.format_date(d);
		var cells = this.$root.querySelectorAll(`.dhx_cal_data .dhx_cal_datepicker_date[data-cell-date="${dateString}"]`);
		for(var i = 0; i < cells.length; i++){
			if(!scheduler.$domHelpers.closest(cells[i], ".dhx_after, .dhx_before")){
				return cells[i];
			}
		}
		return null;
	};

	scheduler._year_marked_cells = {};
	scheduler._mark_year_date = function(date, event) {
		var dateString = scheduler.templates.format_date(date);
		var cell = this._get_year_cell(date);
		if (!cell) {
			return;
		}
		var ev_class = this.templates.event_class(event.start_date, event.end_date, event);
		if (!scheduler._year_marked_cells[dateString]) {
			cell.classList.add("dhx_year_event", "dhx_cal_datepicker_event");// = "dhx_month_head dhx_year_event";
			cell.setAttribute("data-year-date", dateString);
			cell.setAttribute("date", dateString);
			scheduler._year_marked_cells[dateString] = cell;
		}
		if(ev_class){
			cell.classList.add(ev_class);
		}
	};
	scheduler._unmark_year_date = function(date) {
		var cell = this._get_year_cell(date);
		if (!cell) {
			return;
		}
		cell.classList.remove("dhx_year_event", "dhx_cal_datepicker_event");
//		cell.className = "dhx_month_head";
	};
	scheduler._year_render_event = function(event) {
		var date = event.start_date;
		if (date.valueOf() < this._min_date.valueOf()){
			date = this._min_date;
		} else {
			date = this.date.date_part(new Date(date));
		}

		while (date < event.end_date) {
			this._mark_year_date(date, event);
			date = this.date.add(date, 1, "day");
			if (date.valueOf() >= this._max_date.valueOf())
				return;
		}
	};

	scheduler.year_view = function(mode) {

		scheduler.set_sizes();

		scheduler._table_view = mode;

		if (this._load_mode && this._load()) return;

		if (mode) {
			scheduler._init_year_tooltip();
			scheduler._reset_year_scale();
			if (scheduler._load_mode && scheduler._load()){
				scheduler._render_wait = true;
				return;
			}
			scheduler.render_view_data();
		} else {
			scheduler._hideToolTip();
		}
	};
	scheduler._reset_year_scale = function() {
		this._cols = [];
		this._colsS = {};
		var week_starts = []; //start day of first week in each month
		var dataArea = this._els["dhx_cal_data"][0];

		var c = this.config;
		dataArea.scrollTop = 0; //fix flickering in FF
		dataArea.innerHTML = "";

		var dy = Math.floor((parseInt(dataArea.style.height) - scheduler.xy.year_top) / c.year_y);
		if (dy < 190) {
			dy = 190;
		}

		var week_template = document.createElement("div");
		var dummy_date = this.date.week_start(scheduler._currentDate());

		this._process_ignores(dummy_date, 7, "day", 1);

		for (var i = 0; i < 7; i++) {
			if(!(this._ignores && this._ignores[i])) {
				this._cols[i] = 'var(--dhx-scheduler-datepicker-cell-size)';
				this._render_x_header(i, 0, dummy_date, week_template);

			}
			dummy_date = this.date.add(dummy_date, 1, "day");
		}
		week_template.lastChild.className += " dhx_scale_bar_last";

		for(var i = 0; i < week_template.childNodes.length; i++){
			this._waiAria.yearHeadCell(week_template.childNodes[i]);
		}

		var sd = this.date[this._mode + "_start"](this.date.copy(this._date));
		var ssd = sd;
		var yearBox = null;
		const wrapper = document.createElement("div");
		wrapper.classList.add("dhx_year_wrapper");
		for (var i = 0; i < c.year_y; i++){
			for (var j = 0; j < c.year_x; j++) {
				yearBox = document.createElement("div");
				yearBox.className = "dhx_year_box";

				yearBox.setAttribute("date", this._helpers.formatDate(sd));
				yearBox.setAttribute("data-month-date", this._helpers.formatDate(sd));
				yearBox.innerHTML = "<div class='dhx_year_month'></div><div class='dhx_year_grid'><div class='dhx_year_week'>" + week_template.innerHTML + "</div><div class='dhx_year_body'></div></div>";

				var header = yearBox.querySelector(".dhx_year_month");
				var grid = yearBox.querySelector(".dhx_year_grid");
				var body = yearBox.querySelector(".dhx_year_body");

				var headerId = scheduler.uid();
				this._waiAria.yearHeader(header, headerId);
				this._waiAria.yearGrid(grid, headerId);


				header.innerHTML = this.templates.year_month(sd);



				var dd = this.date.week_start(sd);
				this._reset_month_scale(body, sd, dd, 6);

				var days = body.querySelectorAll("td");
				for(var day = 0; day < days.length; day++){
					this._waiAria.yearDayCell(days[day]);
				}

				wrapper.appendChild(yearBox);

				week_starts[i * c.year_x + j] = (sd.getDay() - (this.config.start_on_monday ? 1 : 0) + 7) % 7;
				sd = this.date.add(sd, 1, "month");

			}
		}

		dataArea.appendChild(wrapper);

		var dateElement = this._getNavDateElement();
		if(dateElement){
			dateElement.innerHTML = this.templates[this._mode + "_date"](ssd, sd, this._mode);
		}

		this.week_starts = week_starts;
		week_starts._month = ssd.getMonth();
		this._min_date = ssd;
		this._max_date = sd;
	};

	scheduler._reset_year_scale = function(){

		var dataArea = this._els["dhx_cal_data"][0];

		dataArea.scrollTop = 0; //fix flickering in FF
		dataArea.innerHTML = "";

		let yearStart = this.date.year_start(new Date(this._date));
		
		this._min_date = this.date.week_start(new Date(yearStart));

		const wrapper = document.createElement("div");
		wrapper.classList.add("dhx_year_wrapper");

		let currentDate = yearStart;
		for(let i = 0; i < 12; i++){
			let yearBox = document.createElement("div");
			yearBox.className = "dhx_year_box";

			yearBox.setAttribute("date", this._helpers.formatDate(currentDate));
			yearBox.setAttribute("data-month-date", this._helpers.formatDate(currentDate));
			yearBox.innerHTML = `<div class='dhx_year_month'>${this.templates.year_month(currentDate)}</div>
			<div class='dhx_year_grid'></div>`;

			const yearGrid = yearBox.querySelector(".dhx_year_grid");
			const datepicker = scheduler._createDatePicker(null, {
				date: currentDate,
				filterDays: scheduler.ignore_year,
				minWeeks: 6
			});
			datepicker._renderDayGrid(yearGrid);
			datepicker.destructor();

			wrapper.appendChild(yearBox);

			currentDate = this.date.add(currentDate, 1, "month");

		}
		dataArea.appendChild(wrapper);

		let lastDate = this.date.add(yearStart, 1, "year");
		if(lastDate.valueOf() != this.date.week_start(new Date(lastDate)).valueOf()){
			lastDate = this.date.week_start(new Date(lastDate));
			lastDate = this.date.add(lastDate, 1, "week");
		}
		this._max_date = lastDate;
		var dateElement = this._getNavDateElement();
		if(dateElement){
			dateElement.innerHTML = this.templates[this._mode + "_date"](yearStart, lastDate, this._mode);
		}
	};

	var getActionData = scheduler.getActionData;
	scheduler.getActionData = function(n_ev) {
		if(!isYearMode()){
			return getActionData.apply(scheduler, arguments);
		}
		
		var date = getCellDate(n_ev.target);
		return {
			date:date,
			section:null
		};
	};

	var locateEvent = scheduler._locate_event;
	scheduler._locate_event = function(node) {
		var id = locateEvent.apply(scheduler, arguments);
		if(!id){
			
			var date = getCellDate(node);
			if(!date) return null;
			var evs = scheduler.getEvents(date, scheduler.date.add(date, 1, "day"));
			if(!evs.length) return null;

			//can be multiple events in the cell, return any single one
			id = evs[0].id;
		}
		return id;
	};

	scheduler.attachEvent("onDestroy", function(){
		scheduler._hideToolTip();
	});
}