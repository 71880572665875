export default function(scheduler){

	scheduler.date.add_agenda = function(date, inc){
		return scheduler.date.add(date, 1*inc, "month");
	};
	
	scheduler.templates.agenda_time = function(start,end,ev){
		if (ev._timed){
			return `${this.event_date(start)} - ${this.event_date(end)}`;
		}else{
			return scheduler.locale.labels.full_day;
		}
	};
	scheduler.templates.agenda_text = function(start,end,ev){
		return ev.text;
	};

	const dayDateToStr = scheduler.date.date_to_str("%F %j");
	const dayDowToStr = scheduler.date.date_to_str("%l");

	scheduler.templates.agenda_day = function(date){ 
		return `<div class="dhx_agenda_day_date">${dayDateToStr(date)}</div>
		<div class="dhx_agenda_day_dow">${dayDowToStr(date)}</div>`;
	};
	scheduler.templates.agenda_date = function(start, end){ 
		return scheduler.templates.month_date(scheduler.getState().date); 
	};
	
	scheduler.date.agenda_start=function(date){
		return scheduler.date.month_start(new Date(date));
	};

	let scrollTop = 0;
	
	scheduler.attachEvent("onTemplatesReady",function() {
		var old_dblclick_dhx_cal_data = scheduler.dblclick_dhx_cal_data;
		scheduler.dblclick_dhx_cal_data = function() {
			if (this._mode == "agenda") {
				if (!this.config.readonly && this.config.dblclick_create)
					this.addEventNow();
			} else {
				if (old_dblclick_dhx_cal_data)
					return old_dblclick_dhx_cal_data.apply(this, arguments);
			}
		};
	
	
		var old = scheduler.render_data;
		scheduler.render_data=function(evs){
			if (this._mode == "agenda")
				fill_agenda_tab();
			else
				return old.apply(this,arguments);
		};
	
		var old_render_view_data = scheduler.render_view_data;
		scheduler.render_view_data = function(){
			if(this._mode == "agenda") {
				scrollTop = scheduler._els["dhx_cal_data"][0].scrollTop;
				scheduler._els["dhx_cal_data"][0].scrollTop = 0;
			}
			return old_render_view_data.apply(this,arguments);
		};

		function fill_agenda_tab(){
			const events = scheduler.get_visible_events();
			events.sort(function(a,b){ return a.start_date>b.start_date?1:-1;});

			const eventsInDays = {};
			let currDate = scheduler.getState().min_date;
			const maxDate = scheduler.getState().max_date;
			while(currDate.valueOf() < maxDate.valueOf()){
				eventsInDays[currDate.valueOf()] = [];
				currDate = scheduler.date.add(currDate, 1, "day");
			}

			let anyEvents = false;

			events.forEach((ev) => {
				let eventStart = scheduler.date.day_start(new Date(ev.start_date)); 
				while (eventStart.valueOf() < ev.end_date.valueOf()) {
					if (eventsInDays[eventStart.valueOf()]) {
						eventsInDays[eventStart.valueOf()].push(ev);
						anyEvents = true;
					}
					eventStart = scheduler.date.day_start(scheduler.date.add(eventStart, 1, "day"));
				}
			});

			if(!anyEvents){
				scheduler._els["dhx_cal_data"][0].innerHTML = renderEmptyView();
			}else{
				let html = "";
				for(let day in eventsInDays){
					if(scheduler.ignore_agenda && scheduler.ignore_agenda(new Date(day*1))){
						continue;
					}
					html += renderDay(new Date(day*1), eventsInDays[day]);
				}

				scheduler._els["dhx_cal_data"][0].innerHTML = html;
			}

			scheduler._els["dhx_cal_data"][0].scrollTop = scrollTop;
			
			let t = scheduler._els["dhx_cal_data"][0].querySelectorAll(".dhx_cal_agenda_event_line");
			scheduler._rendered = [];
			for (var i = 0; i < t.length - 1; i++) {
				scheduler._rendered[i] = t[i];
			}
		}

	

		function renderEmptyView(){
			return `<div class="dhx_cal_agenda_no_events">${scheduler.locale.labels.agenda_tab}</div>`;
		}

		function renderDay(day, events) {
			if(!events.length){
				return "";
			}
			let html = `
<div class="dhx_cal_agenda_day" data-date="${scheduler.templates.format_date(day)}" data-day="${day.getDay()}">
	<div class="dhx_cal_agenda_day_header">${scheduler.templates.agenda_day(day)}</div>
	<div class="dhx_cal_agenda_day_events">
`;

			events.forEach((calendarEvent) => {
				html += renderOneEvent(day, calendarEvent);
			});

			html += `</div></div>`;
			return html;
		}

		function renderOneEvent(day, calendarEvent){
			const dates = scheduler.templates.agenda_time(calendarEvent.start_date, calendarEvent.end_date, calendarEvent);
			const selectedId = scheduler.getState().select_id;
			const cls = scheduler.templates.event_class(calendarEvent.start_date, calendarEvent.end_date, calendarEvent);
			const description = scheduler.templates.agenda_text(calendarEvent.start_date, calendarEvent.end_date, calendarEvent);

			let style = "";
			if(calendarEvent.color || calendarEvent.textColor){
				const bg = (calendarEvent.color ? ("--dhx-scheduler-event-background:" + calendarEvent.color + ";") : "");
				const color = (calendarEvent.textColor ? ("--dhx-scheduler-event-color:" + calendarEvent.textColor + ";") : "");
				style = ` style="${bg}${color}" `;
			}

			return `<div class="dhx_cal_agenda_event_line ${cls||""} ${calendarEvent.id == selectedId ? "dhx_cal_agenda_event_line_selected" : ""}" ${style} ${scheduler.config.event_attribute}="${calendarEvent.id}">
	<div class="dhx_cal_agenda_event_line_marker"></div>
	<div class="dhx_cal_agenda_event_line_time">${dates}</div>
	<div class="dhx_cal_agenda_event_line_text">${description}</div>
</div>`;
		}
	
		scheduler.agenda_view=function(mode){
			if (mode){
				scheduler._min_date = scheduler.config.agenda_start || scheduler.date.agenda_start(scheduler._date);
				scheduler._max_date = scheduler.config.agenda_end || scheduler.date.add_agenda(scheduler._min_date, 1);
				scheduler._cols = null;
				scheduler._colsS = null;
				scheduler._table_view = true;

				const dateHeader = scheduler._getNavDateElement();
				dateHeader.innerHTML = scheduler.templates.agenda_date(scheduler._date);
				//agenda tab activated
				fill_agenda_tab();
			} else {
				scheduler._table_view = false;
				//agenda tab de-activated
			}
		};
	});

	// scheduler.attachEvent("onEventSelected", function(id){
	// 	const oldSelected = scheduler.$container.querySelectorAll(".dhx_cal_agenda_event_line_selected");
	// 	oldSelected.forEach((el) => {
	// 		el.classList.remove("dhx_cal_agenda_event_line_selected");
	// 	});

	// 	const selected = scheduler.$container.querySelector(`[${scheduler.config.event_attribute}="${id}"]`);
	// 	if(selected){
	// 		selected.classList.add("dhx_cal_agenda_event_line_selected");
	// 	}
	// });
	
	
}