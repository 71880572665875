import eventable from "../utils/eventable";

export class DatePicker {
	constructor(scheduler, container, state = {}) {
		this.state = {
			date: new Date(),
			modes: ['days', 'months', 'years'],
			currentRange: [],
			eventDates: [],
			filterDays: null,
			currentModeIndex: 0,
			...state
		};
		this.container = null;
		this.element = null;
		this.onStateChangeHandlers = [];
		this.scheduler = scheduler;
		this._domEvents = scheduler._createDomEventScope();
		this.state = this.getState();
		eventable(this);
		if(container){

			this.container = container;
			this.render(this.container);
		}

		this.onStateChange((oldState, newState) => {
			this.callEvent("onStateChange", [newState, oldState]);
		});
	}

	getState() {
		return { 
			...this.state,
			mode: this.state.modes[this.state.currentModeIndex]
		};
	}

	setState(newState) {
		const oldState = { ...this.state };
		if(newState.mode){
			newState.currentModeIndex = this.state.modes.indexOf(newState.mode);
		}
		this.state = { ...this.state, ...newState };

		this._notifyStateChange(oldState, this.state);

		if (this.container) {
			this.render(this.container);
		}
	}

	onStateChange(handler) {
		this.onStateChangeHandlers.push(handler);

		// function to remove the handler
		return () => {
			const index = this.onStateChangeHandlers.indexOf(handler);
			if (index !== -1) {
				this.onStateChangeHandlers.splice(index, 1);
			}
		};
	}

	_notifyStateChange(oldState, newState) {
		this.onStateChangeHandlers.forEach(handler => handler(oldState, newState));
	}

	_adjustDate(direction) {

		const {mode, date} = this.getState();
		const newDate = new Date(date);
		if (mode === 'days') {
			newDate.setMonth(date.getMonth() + direction);
		} else if (mode === 'months') {
			newDate.setFullYear(date.getFullYear() + direction);
		} else {
			newDate.setFullYear(date.getFullYear() + direction * 10);
		}
		this.setState({ date: newDate });
	}

	_toggleMode() {
		const newIndex = (this.state.currentModeIndex + 1) % this.state.modes.length;
		this.setState({ currentModeIndex: newIndex });
	}

	_renderCalendarHeader(container) {
		const {mode, date} = this.getState();

		const header = document.createElement('div');
		header.classList.add('dhx_cal_datepicker_header');

		const backwardArrow = document.createElement('button');

		backwardArrow.classList.add("dhx_cal_datepicker_arrow", "scheduler_icon", "arrow_left");
		header.appendChild(backwardArrow);

		const monthLabel = document.createElement('div');
		monthLabel.classList.add('dhx_cal_datepicker_title');

		if (mode === 'days') {
			monthLabel.innerText = date.toLocaleString('default', { month: 'long' }) + ' ' + date.getFullYear();
		} else if (mode === 'months') {
			monthLabel.innerText = date.getFullYear();
		} else {
			const startYear = Math.floor(date.getFullYear() / 10) * 10;
			monthLabel.innerText = `${startYear} - ${startYear + 9}`;
		}

		this._domEvents.attach(monthLabel, "click", this._toggleMode.bind(this));
		header.appendChild(monthLabel);

		const forwardArrow = document.createElement('button');

		forwardArrow.classList.add("dhx_cal_datepicker_arrow", "scheduler_icon", "arrow_right");
		header.appendChild(forwardArrow);

		container.appendChild(header);

		this._domEvents.attach(backwardArrow, "click", this._adjustDate.bind(this, -1));
		this._domEvents.attach(forwardArrow, "click", this._adjustDate.bind(this, 1));
	}

	render(container) {
		this._domEvents.detachAll();
		this.container = container || this.container; 
		this.container.innerHTML = '';

		if(!this.element){
			this.element = document.createElement("div");
			this.element.classList.add("dhx_cal_datepicker");
		}
		this.element.innerHTML = '';
		this.container.appendChild(this.element);

		this._renderCalendarHeader(this.element);
		const dataContainer = document.createElement("div");
		dataContainer.classList.add("dhx_cal_datepicker_data");
		this.element.appendChild(dataContainer);

		const {mode} = this.getState();
		if (mode === 'days') {
			this._renderDayGrid(dataContainer);
		} else if (mode === 'months') {
			this._renderMonthGrid(dataContainer);
		} else {
			this._renderYearGrid(dataContainer);
		}
	}

	_renderDayGridHeader(daysOfWeekContainer) {
		const {date, filterDays} = this.getState();
		const scheduler = this.scheduler;

		let currentDate = scheduler.date.week_start(new Date(date));
		const maxDate = scheduler.date.add(scheduler.date.week_start(new Date(date)), 1, 'week');

		daysOfWeekContainer.classList.add('dhx_cal_datepicker_days');

		const labelFormat = scheduler.date.date_to_str("%D");

		while (currentDate.valueOf() < maxDate.valueOf()) {
			if(!(filterDays && filterDays(currentDate))){
				const label = labelFormat(currentDate);

				const dayElement = document.createElement('div');
				dayElement.setAttribute("data-day", currentDate.getDay());
				dayElement.classList.add('dhx_cal_datepicker_dayname');
				dayElement.innerText = label;
				daysOfWeekContainer.appendChild(dayElement);
			}

			currentDate = scheduler.date.add(currentDate, 1, 'day');
		}
	}

	_weeksBetween(min, max) {
		const scheduler = this.scheduler;
		let weeks = 0;
		let currWeek = new Date(min);
		while(currWeek.valueOf() < max.valueOf()){
			weeks += 1;
			currWeek = scheduler.date.week_start(scheduler.date.add(currWeek, 1, "week"));
		}
		return weeks;
	}

	_renderDayGrid(container) {
		const {date, currentRange, eventDates, minWeeks, filterDays} = this.getState();

		let minSchedulerDate = currentRange[0];
		let maxSchedulerDate = currentRange[1];

		const eventDaysTable = eventDates.reduce((acc, date) => {
			const dayStart = this.scheduler.date.day_start(new Date(date));
			acc[dayStart.valueOf()] = true;
			return acc;
		}, {});


		// Render Days of the Week
		const daysOfWeekContainer = document.createElement('div');
		this._renderDayGridHeader(daysOfWeekContainer);
		const weekLength = daysOfWeekContainer.children.length;
		container.appendChild(daysOfWeekContainer);
		if(weekLength !== 7){
			container.style.setProperty("--dhx-scheduler-week-length", weekLength);
		}

		const scheduler = this.scheduler;
		const firstDate = scheduler.date.week_start(scheduler.date.month_start(new Date(date)));
		const monthStart = scheduler.date.month_start(new Date(date));
		const monthEnd = scheduler.date.add(scheduler.date.month_start(new Date(date)), 1, 'month');
		let lastDate = scheduler.date.add(scheduler.date.month_start(new Date(date)), 1, 'month');
		const currentCalDate = scheduler.date.date_part(scheduler._currentDate());
		if(lastDate.getDay() !== 0){
			lastDate = scheduler.date.add(scheduler.date.week_start(lastDate), 1, "week");
		}

		let weeks = this._weeksBetween(firstDate, lastDate);
		if(minWeeks && weeks < minWeeks){
			lastDate = scheduler.date.add(lastDate, (minWeeks - weeks), "week");
		}

		let currDate = firstDate;

		const dayGridContainer = document.createElement('div');
		dayGridContainer.classList.add('dhx_cal_datepicker_days');

		this._domEvents.attach(dayGridContainer, "click", (event) => {
			const dateCell = event.target.closest("[data-cell-date]");
			const date = new Date(dateCell.getAttribute("data-cell-date"));
			this.callEvent("onDateClick", [date, event]);
		});

		while(currDate.valueOf() < lastDate.valueOf()){
			if(!(filterDays && filterDays(currDate))){

				const dayElement = document.createElement('div');
				dayElement.setAttribute("data-cell-date", scheduler.templates.format_date(currDate));
				dayElement.setAttribute("data-day", currDate.getDay());
				dayElement.innerHTML = currDate.getDate();

				if(currDate.valueOf() < monthStart.valueOf()){
					dayElement.classList.add('dhx_before');
				}else if(currDate.valueOf() >= monthEnd.valueOf()){	
					dayElement.classList.add('dhx_after');
				}

				if(currDate.getDay() === 0 || currDate.getDay() === 6){
					dayElement.classList.add("dhx_cal_datepicker_weekend");
				}

				if(currDate.valueOf() == currentCalDate.valueOf()){
					dayElement.classList.add("dhx_now");
				}

				if(minSchedulerDate && maxSchedulerDate){
					if(currDate.valueOf() >= minSchedulerDate.valueOf() && currDate.valueOf() < maxSchedulerDate.valueOf()){
						dayElement.classList.add('dhx_cal_datepicker_current');
					}
				}

				if(eventDaysTable[currDate.valueOf()]){
					dayElement.classList.add('dhx_cal_datepicker_event');
				}
				
				dayElement.classList.add('dhx_cal_datepicker_date');

				dayGridContainer.appendChild(dayElement);
			}
			currDate = scheduler.date.add(currDate, 1, 'day');
		}

		container.appendChild(dayGridContainer);

	}

	_renderMonthGrid(container) {
		const {date} = this.getState();

		const wrapper = document.createElement('div');
		wrapper.classList.add("dhx_cal_datepicker_months");

		const months = [];
		for(let i = 0; i < 12; i++){
			months.push(new Date(date.getFullYear(), i, 1));
		}

		const formatLabel = this.scheduler.date.date_to_str("%M");
		months.forEach(month => {
			const monthElement = document.createElement('div');
			monthElement.classList.add('dhx_cal_datepicker_month');
			if(date.getMonth() === month.getMonth()){
				monthElement.classList.add('dhx_cal_datepicker_current');
			}
			monthElement.setAttribute("data-month", month.getMonth());
			monthElement.innerHTML = formatLabel(month);
			this._domEvents.attach(monthElement, "click", () => {
				const newDate = new Date(month);
				this.setState({
					date: newDate,
					mode: 'days'
				});
			});
			wrapper.appendChild(monthElement);
		});
		container.appendChild(wrapper);

		const doneArea = document.createElement("div");
		doneArea.classList.add("dhx_cal_datepicker_done");
		const doneBtn = document.createElement("button");
		doneBtn.innerText = "Done";
		doneBtn.classList.add("dhx_cal_datepicker_done_btn");
		this._domEvents.attach(doneBtn, "click", () => {
			this.setState({
				mode: 'days'
			});
		});
		doneArea.appendChild(doneBtn);
		container.appendChild(doneArea);
	}

	_renderYearGrid(container) {
		const {date} = this.getState();
		const startYear = Math.floor(date.getFullYear() / 10) * 10;
		
		const wrapper = document.createElement('div');
		wrapper.classList.add("dhx_cal_datepicker_years");
		for (let i = startYear - 1; i <= startYear + 10; i++) {
			const yearElement = document.createElement('div');
			yearElement.innerText = i;
			yearElement.classList.add('dhx_cal_datepicker_year');
			yearElement.setAttribute("data-year", i);
			if(date.getFullYear() === i){
				yearElement.classList.add('dhx_cal_datepicker_current');
			}
			this._domEvents.attach(yearElement, "click", () => {
				this.setState({
					date: new Date(i, date.getMonth(), 1),
					mode: 'months'
				});
			});
			wrapper.appendChild(yearElement);
			
		}
		container.appendChild(wrapper);

		const doneArea = document.createElement("div");
		doneArea.classList.add("dhx_cal_datepicker_done");
		const doneBtn = document.createElement("button");
		doneBtn.innerText = "Done";
		doneBtn.classList.add("dhx_cal_datepicker_done_btn");
		this._domEvents.attach(doneBtn, "click", () => {
			this.setState({
				mode: 'months'
			});
		});
		doneArea.appendChild(doneBtn);
		container.appendChild(doneArea);
	}

	destructor(){
		this.onStateChangeHandlers = [];
		if(this.element){
			this.element.innerHTML = '';
			this.element.remove();
		}

		this._domEvents.detachAll();
		this.callEvent("onDestroy", []);
		this.detachAllEvents();

		this.scheduler = null;
	}
}